import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AccountApiService {

  constructor(
      private apiService: ApiService
  ) {
  }

  get(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.get(environment.accountURL, path, param, header, showLoader)
        .pipe(catchError(this.handleError('Get - Accounts')));
  }

  put(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.put(environment.accountURL, path, param, header, body, showLoader)
        .pipe(catchError(this.handleError('Put - Accounts')));
  }

  post(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.post(environment.accountURL, path, param, header, body, showLoader)
        .pipe(catchError(this.handleError('Post - Accounts')));
  }

  postFormData(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), body: Object = {}, showLoader?: Boolean): Observable<any> {
    return this.apiService.postFormData(environment.accountURL, path, param, header, body, showLoader)
        .pipe(catchError(this.handleError('Post - Accounts')));
  }

  delete(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.delete(environment.accountURL, path, param, header, showLoader)
        .pipe(catchError(this.handleError('Delete - Accounts')));
  }

  getIpAddress(header: HttpHeaders = new HttpHeaders()): Observable<any> {
    return this.apiService.getIp(header)
        .pipe(catchError(this.handleError('GetIP - Accounts')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      return of(result as T);
    };
  }

  getNew(path: string, param: HttpParams = new HttpParams(), header: HttpHeaders = new HttpHeaders(), showLoader?: Boolean): Observable<any> {
    return this.apiService.getNew(environment.accountNewURL, path, param, header, showLoader)
        .pipe(catchError(this.handleError('Get - Accounts')));
  }
}
