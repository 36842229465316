import { Injectable } from '@angular/core';
import { EcommerceAPIService } from '../../../../core/http/ecommerce/ecommerce-api.service';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { OutletDetail, Product } from '../../../../core/models/integration/ecommerce';
import { Observable, of } from 'rxjs';
import { ConfigData } from '../../ecommerce/pages/configuration/data';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OndcConfig } from '../../../../core/models';
import { OndcApiService } from '../../../../core/http/ondc/ondc-api.service';
import { AccountApiService } from '../../../../core/http/account/account-api.service';

@Injectable({
  providedIn: 'root'
})
export class OndcConfigService {

  configData = ConfigData;
  countriesJson = '../../../../../assets/ondc/item-master-config/countries.json';
  colorAttributes = '../../../../../assets/ondc/item-master-config/color-attributes.json';
  attributesUnits = "../../../../../assets/ondc/item-master-config/attribute-units.json"
  constructor(
    private authenticationService: AuthenticationService,
    private ecommerceAPIService: EcommerceAPIService,
    private ondcApiService: OndcApiService,
    private accountApiService: AccountApiService,
    private http: HttpClient

  ) { }

  public getOutletDetails(): Observable<OutletDetail[]> {
    let headers = new HttpHeaders().set('X-Auth-Token', this.authenticationService.getAuthToken())
    .set('key', window.btoa(this.authenticationService.getEmailId()))
    .set('userId', ''+this.authenticationService.getServiceUserId())
    .set('skew', this.authenticationService.getSkewCode());
    return this.accountApiService.post('list-store', new HttpParams(), headers, null, true);
  }

  public getProduct(): Observable<Product[]> {
    return of(this.configData.urbanpiper.product);
  }

  public getOndcConfig(outletId: number): Observable<OndcConfigService> {
    const header = new HttpHeaders().set('X-Auth-Token', this.authenticationService.getAuthToken())
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('outletId', '' + outletId)
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get/configured-store', param, header, true);
  }
  public testURL(url: string, product: string): Observable<any> {
    const param = new HttpParams().set('url', window.btoa(url)).set('product', product)
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('store/test/domain-url', param);
  }
  public saveConfiguration(selectedOutletId: any, body: OndcConfig): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', selectedOutletId);
    return this.ecommerceAPIService.post('configure', param, header, body, true);
  }

  public getAppConfig(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('from', 'portal');
    return this.ecommerceAPIService.get('742/get-config', param);
  }

  //Get shipping configuration
  // public getShippingConfig(): Observable<any> {
  //   const header = new HttpHeaders().set('Content-Type', 'application/json');
  //   const param = new HttpParams()
  //     .set('userId', '' + this.authenticationService.getServiceUserId())
  //     .set('vendor', this.authenticationService.getSkewName());
  //   const body = {};
  //   body['userId'] = '' + this.authenticationService.getServiceUserId();
  //   body['vendor'] = this.authenticationService.getSkewName();
  //   return this.ecommerceAPIService.post('v1/get/delivery-type', param, header, body, true);
  // }

  //add or update shipping configuration
  public saveShippingConfig(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('v1/add/delivery-type', param, header, body, true);
  }

  //Get Add on list in order processing screen
  public getOndcListInOrderProcessingScreen(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('hasSalesScreen', '' + true)
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get/addons', param, null, true);
  }

  // Get Order status for order processing screen 
  public getOndcOrderStatusforOrderProcessingScreen(outletId?, vendor?): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId);
    return this.ecommerceAPIService.get('get/orderStatus', param, null, true);
  }

  public getOrders(searchTag: string, status: string, pageIndex: number,
    pageSize: number, showLoader: Boolean = false, outletId?: any, orderSource?: string, orderExpressSource?: string,
    startFilterDate?: string, endFilterDate?: string,
    filterSlotDate?, filterSlotId?, orderSort?: string, loader?: boolean): Observable<any> {
    if (startFilterDate === undefined || endFilterDate === undefined) {
      startFilterDate = '';
      endFilterDate = '';
      filterSlotDate = '';
      filterSlotId = '';
    }
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId ? outletId : this.authenticationService.getOutlet())
      .set('searchTag', searchTag)
      .set('status', status)
      .set('source', orderSource ? orderSource : 'both')
      .set('startFilterDate', startFilterDate)
      .set('endFilterDate', endFilterDate)
      .set('deliverySlotDate', filterSlotDate)
      .set('deliverySlotId', filterSlotId)
      .set('deliveryType', orderExpressSource ? orderExpressSource : 'both')
      .set('pageIndex', pageIndex.toString())
      .set('pageSize', pageSize.toString())
      .set('orderSort', orderSort);
    return this.ondcApiService.get('ondc-service/get/order', param, null, showLoader);
  }

  public getOrderDetails(orderId: string, onlineReferenceNo: string, outletId?: any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId ? outletId : this.authenticationService.getOutlet())
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('orderId', orderId);
    return this.ondcApiService.get('ondc-service/get/order/id', param, null, true);
  }

  public getRiderInfo(orderId: string, onlineReferenceNo: string): Observable<any> {
    const param = new HttpParams()
      .set('vendor', this.authenticationService.getSkewName())
      .set('orderId', orderId)
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ecommerceAPIService.get('urbanpiper/get/rider-status', param, null, true);
  }

  public pushOrderPOS(): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ondcApiService.get('ondc-service/push/order/pos', param, headers, false);
  }

  public updateOrderStatus(orderId: string, onlineReferenceNo: string, toStatus: string, body: any, message: string, ondcCoreVersion: string, rtoRetryCount: string): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('orderId', orderId)
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('status', toStatus)
      .set('message', message)
      .set('ondcCoreVersion', ondcCoreVersion)
      .set('retryCount', rtoRetryCount);
    return this.ondcApiService.post('ondc-service/update-order-status', param, header, body, true);
  }

  public pushSingleOrderPOS(onlineReferenceNo: string, onlineVendorSoNo: string): Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` });
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('onlineReferenceNo', onlineReferenceNo)
      .set('onlineVendorSoNo', onlineVendorSoNo);
    return this.ondcApiService.get('ondc-service/push/order/pos', param, headers, false);
  }

  public riderTemperatureUpdate(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('vendor', this.authenticationService.getSkewName())
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ecommerceAPIService.post('urbanpiper/update/rider-temperature', param, header, body, true);
  }

  public uploadShopImage(outletId: string, image: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId);
    return this.ondcApiService.postFormData('ondc-service/configure-shop-image', param, header, image, true);
  }

  public sellerConfig(boby: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId())
    .set('vendor', this.authenticationService.getSkewName())
    return this.ondcApiService.post('ondc-service/seller-config', param, header, boby, true);
  }

  public fetchSellerConfig(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId())
    .set('vendor', this.authenticationService.getSkewName())
    .set('outletId', this.authenticationService.getSelectedOutletId())
    return this.ondcApiService.get('ondc-service/fetch-seller-config', param, header, true);
  }

  // Get Payment list for online payment based on userID
  public getPaymentListForOnlineBasedUser(isMobile): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('fromMobile', isMobile)
      .set('paymentType', 'paymentOnOnline');
    return this.ondcApiService.get('ondc-service/get-payment-config', param);
  }

  public getOnlinePaymentKeys(showLoader, outletId:any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId);
    return this.ondcApiService.get('ondc-service/get-online-payment-keys', param, null, showLoader);
  }

  public addOnlinePaymentKeys(body: any, forAllOutlet): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      // .set('vendor', this.authenticationService.getSkewName())
      // .set('outletId', "" + 0)
      .set('forAllOutlet', forAllOutlet);
    return this.ondcApiService.post('ondc-service/add-online-payment-keys', param, header, body, true);
  }

  public getSettlementDetails(): Observable<any>{
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', this.authenticationService.getOutlet());
      return this.ondcApiService.get('ondc-service/get-settlement-details', param, null, false)
  }

  public configureSettlementDetails(body: any): Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.post('ondc-service/configure-settlement-details', param, header, body, true)
  }

  public getAccountDetails(outletId: number): Observable<any>{
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId);
      return this.ondcApiService.get('ondc-service/get-settlement-details', param, null, true)
  }

  public saveAccountDetails(body: any): Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.post('ondc-service/configure-settlement-details', param, header, body, true)
  }

  public getDeliveryConfigurationList(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('deliveryConfigName', 'DCA');
    return this.ondcApiService.get('ondc-service/delivery-charge', param, header, false);
  }

  public addUpdateDeliveryConfigList(body: any):Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
    return this.ondcApiService.post('ondc-service/delivery-charge', param, header, body, false);
  }

  public getStdCodeList():Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.get('ondc-service/store/stdcodes', param, header, true)
  }

  public getConfiguration(): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get/configured-store', param);
  }

  getCategoryForItemMaster(outletSelected: string): Observable<any> {
    let formObj = {
      "userId": this.authenticationService.getServiceUserId(),
      "vendor": this.authenticationService.getSkewName(),
      "outletId": outletSelected ? outletSelected : '-1',
    }
    const header = new HttpHeaders().set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('v1/get/category-filter', param, header, formObj, true);
  }

  public getItemMaster(outletSelected: string, pageIndex: string, pageSize: any, categoryList: any, filter: string): Observable<any> {
    const header = new HttpHeaders({ timeout: `${30000}` }).set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    const body = {
      'userId' : '' + this.authenticationService.getServiceUserId(),
      'vendor' : this.authenticationService.getSkewName(),
      'outletId' : outletSelected ? outletSelected : '1',
      'orFilter' : categoryList ? categoryList : '',
      'filter' : filter,
      'pageIndex': pageIndex,
    };
    return this.ecommerceAPIService.post('v1/get/item-master/report', param, header, body, true);
  }

  getNoImageItemMaster(outletSelected: string, filter: any, orFilter: any, pageIndex: any, pageSize: any, appliesOnline): Observable<any> {
    const formObj = {
      'userId': this.authenticationService.getServiceUserId(),
      'vendor': this.authenticationService.getSkewName(),
      'outletId': outletSelected ? outletSelected : '1',
      'filter': filter ? filter : '',
      'orFilter': orFilter ? orFilter : '',
      'pageIndex': pageIndex,
      'appliesOnline': appliesOnline
    }
    const header = new HttpHeaders({ timeout: `${30000}` }).set('Content-Type', 'application/json')
      .set('X-Auth-Token', this.authenticationService.getAuthToken());
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ondcApiService.post('ondc-service/get/no-image-items', param, header, formObj, true);
  }

  public saveImageAsBase64(itemId: string, formData: FormData, type: any): Observable<any> {
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', this.authenticationService.getSelectedOutletId())
      .set('skuCode', itemId)
      .set('type', type);
    return this.ondcApiService.postFormData('ondc-service/upload-images', param, null, formData, true);
  }

  public replaceImageAsBase64(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
    return this.ecommerceAPIService.post('v1/update-image', param, header, body, true);
  }

  public deleteItemImage(body: any, type: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('type', type)
    return this.ondcApiService.post('ondc-service/delete-image', param, header, body, true);
  }

  public getVerificationKeyDetails(){
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.get('ondc-service/fetch-onboard-details', param, header, true);
  }

  public saveVerificationKeyDetails(body: any): Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId', ''+ this.authenticationService.getServiceUserId())
    return this.ondcApiService.post('ondc-service/verify-key', param, header, body, true)
  }

  public getIssueTickets(body, outletId): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', outletId);
    return this.ondcApiService.post('ondc-service/fetch-tickets', param, header, body, true);
  }

  public updateIssueTickets(body, isAddRemarkAlone): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ondcApiService.post('ondc-service/update-ticket', param, header, body, !isAddRemarkAlone);
  }

  public syncOrderEasyImage():Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId', ''+this.authenticationService.getServiceUserId())
    .set('outletId', this.authenticationService.getSelectedOutletId());
    return this.ondcApiService.post('ondc-service/get-item-image-urls',param, header, null, true)

  }

  public getReturnOrder(pageIndex: string, returnStatus: string):Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId',`${this.authenticationService.getServiceUserId()}`)
    .set('outletId', `${this.authenticationService.getSelectedOutletId()}`)
    .set('pageIndex', pageIndex)
    .set('pageSize', "20")
    .set('status', returnStatus);
    return this.ondcApiService.get('ondc-service/get-returns', param, header, true)
  }

  public getReturnOrderItem(orderId: string, returnId: string):Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId',`${this.authenticationService.getServiceUserId()}`)
    .set('orderId', orderId)
    .set('returnId', returnId);
    return this.ondcApiService.get('ondc-service/get-return-items', param, header, true)
  }

  public updateReturnStatus(body: any):Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
    .set('userId',`${this.authenticationService.getServiceUserId()}`);
    return this.ondcApiService.post('ondc-service/update-returns', param, header, body, true)
  }
  
  public getSyncConfig(outletId: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', String(this.authenticationService.getServiceUserId()))
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletId);
    return this.ecommerceAPIService.get( 'get/sync-config', param, header, true);
  }

  public saveSyncConfig(body: any, outletId: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', String(this.authenticationService.getServiceUserId()))
        .set('vendor', this.authenticationService.getSkewName())
        .set('outletId', outletId);
    return this.ecommerceAPIService.post('save/sync-config', param, header, body, true);
  }

  public saveScheduleConfig(task: any, cronString: any, schedule: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', String(this.authenticationService.getServiceUserId()))
        .set('vendor', this.authenticationService.getSkewName())
        .set('task', task)
        .set('cron_string', cronString)
        .set('scheduler', schedule);
    return this.ecommerceAPIService.post('schedule', param, header, null, true);
  }

  public getSchedulerConfig(): Observable<any> {
    const param = new HttpParams()
        .set('userId', '' + this.authenticationService.getServiceUserId())
        .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get-scheduler-details', param, null,  true);
  }

  public removeScheduleConfig(task: any, schedule: any, cronExpression): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
        .set('userId', String(this.authenticationService.getServiceUserId()))
        .set('vendor', this.authenticationService.getSkewName())
        .set('task', task)
        .set('scheduler', schedule)
        .set('cronExpression', cronExpression);
    return this.ecommerceAPIService.post('unschedule', param, header, null, true);
}
    
  public getContentMaster(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('v1/get/content-masters', param, header, body, true);
  }

  public saveUserConfigurationForNotification(body): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('v1/user-content', param, header, body, true);
  }

  public partialCancel(body: any):Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.post('ondc-service/partialCancel', param, header, body, true)
  }
    // UTC to IST conversion
   public getUtcToIstConverter(utcDate){
      const dateUTC = new Date(utcDate).getTime() 
      let dateIST = new Date(dateUTC);
      dateIST.setHours(dateIST.getHours() + 5); 
      dateIST.setMinutes(dateIST.getMinutes() + 30);
      return dateIST;
  }
  
  public getCategoryMapping(body: any):Observable<any>{
    const header = new HttpHeaders({timeout : `${50000}`}).set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.post("ondc-service/category/items", param, header, body, true);
  }

  public updateCategoryMapping(body:any):Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.post("ondc-service/category/manual-map", param, header, body, true);
  }

  public autoCategoryMapping(userId, outletId):Observable<any>{
    const header = new HttpHeaders({timeout : `${50000}`}).set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    const body = {userId: userId, outletId: outletId}
    return this.ondcApiService.post("ondc-service/category/auto-map", param, header, body, true);
  }

  public syncTask(endPoint: string, method: string):Observable<any> {
    const headers = new HttpHeaders({ timeout: `${20000}` }).set('Content-Type', 'application/json')
    let param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService[method](endPoint , param, headers, false);
  }

  public getStores():Observable<any>{
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
      return this.ondcApiService.get('ondc-service/stores', param, null, true);
  }
  
  public uploadCancelledCheque(outletId: string, image: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('outletId', outletId);
    return this.ondcApiService.postFormData('ondc-service/upload-cancelled-cheque', param, header, image, true);
  }

  
  public bulkOrderProcess(body: any):Observable<any>{
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ondcApiService.post('/ondc-service/bulk-order-process', param, header, body, true);
  }

  public getCancelCodes():Observable<any> {
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.get('ondc-service/cancel-codes', param, null, true);
  }
  
  public getItemMasterConfig(body: any):Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
  return this.ondcApiService.post('ondc-service/item-config', param, header, body, true);
  }

  public getVerticalBasedCategories(vertical: string):Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vertical', vertical);
    return this.ondcApiService.get('ondc-service/categories', param, header, false);
  }

  public getCategoryBaseAttributes(category: string, itemSkuCode: string, vertical: string):Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('category', category)
      .set('skuCode', itemSkuCode)
      .set('vertical', vertical);
    return this.ondcApiService.get('ondc-service/attributes', param, header, false);
  }

  public saveItemMasterConfig(body: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams().set('userId', '' + this.authenticationService.getServiceUserId());
  return this.ondcApiService.post('ondc-service/save-item-config', param, header, body, true);
  }

  public getListVertical(): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.get('ondc-service/verticals', param, header, false);
  }

  public uploadBackImage(itemSku: string, image: any): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('id', itemSku)
      .set('outletId', this.authenticationService.getSelectedOutletId());
    return this.ondcApiService.postFormData('ondc-service/upload-item-back-image', param, header, image, true);
  }

  public getCountriesList() :Observable<any>{
    return this.http.get<any>(this.countriesJson);
  }

  public getColorAttributes() :Observable<any> {
    return this.http.get<any>(this.colorAttributes);
  }

  public getBulkUploadTemplate(vertical: string, categoryMapping: boolean) :Observable<any> {
    const header = new HttpHeaders({ timeout: `${60000}` }).set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('outletId', this.authenticationService.getSelectedOutletId())
      .set('vertical', vertical)
      .set('categoryMapping', categoryMapping);
    return this.ondcApiService.getXlsxFile('ondc-service/item-config-template', param, header, true);
  }

  public bulkUploadItemConfig(vertical: string, categoryMapping: boolean, file: FormData) :Observable<any>{
    const header = new HttpHeaders({ timeout: `${30000}` }).set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('outletId', this.authenticationService.getSelectedOutletId())
      .set('vertical', vertical)
      .set('categoryMapping', categoryMapping);
    return this.ondcApiService.postFormData('ondc-service/bulk-update-item-config', param, header, file, true);
  }

  public getAttributesUnits() :Observable<any> {
    return this.http.get<any>(this.attributesUnits);
  }

  public getCategoryAndGenderBaseSizes(category: string, gender: string) :Observable<any> {
    const header = new HttpHeaders();
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId())
    .set('category', category)
    .set('gender', gender);
    return this.ondcApiService.get('ondc-service/size-for-category-gender', param, header, false);
  }
  
  public getReturnRejectionCode () :Observable<any> {
    const header = new HttpHeaders();
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId());
    return this.ondcApiService.get('ondc-service/get-return-rejection-codes', param, header, false);
  }

  public getPosLocations(url: string, product: string) :Observable<any> {
    const header = new HttpHeaders();
    const param = new HttpParams()
    .set('userId', '' + this.authenticationService.getServiceUserId())
    .set('url', window.btoa(url))
    .set('product', product);
    return this.ondcApiService.get('ondc-service/store/get/pos-locations', param, header, true);
  }
}
