import * as Stomp from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import {SharedService} from '../../shared/service/shared.service';
import {Injectable} from '@angular/core';
import {EcommerceService} from '../../modules/integration/ecommerce/ecommerce.service';
import {environment} from '../../../environments/environment';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {NotificationService} from '../services/notification.service';
import {LoginService} from '../../modules/login/login.service';
import { TextToSpeech } from '../../text-to-speech.js';
import { FAService } from '../../modules/integration/food-aggregator/fa.service';
import { OndcConfigService } from '../../modules/integration/ondc/ondc-config/ondc-config.service';


@Injectable()
export class StompService {

    disabled = true;
    private reconnected = false;
    private stompClient = null;
    private timer: any;
    private audio = new Audio();
    private notificationAudio = new Audio();
    faSkews = ['535','768','926'];
    paymentSkews = ['730', '720', '808', '751', '931', '915', '777', '911', '774', '932', '919', '934', '940', '746', '942'];

    constructor(
        private sharedService: SharedService,
        private ecomService: EcommerceService,
        private notify: NotificationService,
        private authenticationService: AuthenticationService,
        private router: Router,
        private loginService: LoginService,
        private faService: FAService,
        private ondcService: OndcConfigService
    ) {
    }

    setConnected(connected: boolean) {
        this.disabled = !connected;
    }

    getConnected(): boolean {
        if (this.stompClient) {
            return this.stompClient.connected;
        } else {
            return false;
        }
    }

    playAudio(orderType: string) {
        this.audio.src = `./assets/audio/${orderType}.wav`;
        // this.audio.loop = true;
        this.audio.playbackRate = 5;
        this.audio.load();
        this.audio.play();
    }


    stopAudio() {
        this.audio.pause();
    }

    playNotificationAudio() {
        this.notificationAudio.src = './assets/audio/notification_audio.wav';
        this.notificationAudio.playbackRate = 0;
        this.notificationAudio.load();
        this.notificationAudio.play();
    }

    stopNotificationAudio() {
        this.notificationAudio.pause();
    }

    connect(serviceId) {
        
        let socket
        if(this.faSkews.includes(this.authenticationService.getSkewCode())){
            socket = new SockJS(environment.faURL + 'notification');
        }else if(this.authenticationService.getSkewCode() === "811"){
            socket = new SockJS(environment.ondcURL + 'notification');
        }
        // else {
        //     socket = new SockJS(environment.ecomURL + '../notification');
        // }

        this.stompClient = Stomp.over(socket);
        const _this = this;
        _this.sendPendingOrderCount();
        _this.sendPendingNotificationsCount();
        this.stompClient.connect({}, function (frame) {
                if (_this.reconnected) {
                    _this.sharedService.setReconnected(true);
                    _this.reconnected = false;
                }
                _this.stompClient.subscribe('/topic/' + serviceId, function (response) {
                    const resp = JSON.parse(response.body);
                    _this.sharedService.setAllStatusCount(resp.count);
                    if (response.body && resp.update) {
                        if (_this.faSkews.includes(_this.authenticationService.getSkewCode()) && resp.update.status === 'cancelled') {
                            TextToSpeech('Hi Order number' + resp.update.onlineVendorSoNo + 'has been cancelled');
                        }
                        if (_this.authenticationService.getSkewCode() === '811' && resp.update.status === 'cancelled') {
                            TextToSpeech('Hi Order number' + resp.update.onlineVendorSoNo + 'has been cancelled');
                        }
                    }
                    if (response.body && resp.notification) {
                        _this.playNotificationAudio();
                        _this.getNotificationsList();
                    } else if (response.body && resp.order) {
                        // tslint:disable-next-line:max-line-length
                        if (_this.authenticationService.getSkewCode() === '765' || _this.authenticationService.getSkewCode() === '535' 
                        || _this.authenticationService.getSkewCode() === '811' || _this.authenticationService.getSkewCode() === '768' || _this.authenticationService.getSkewCode() === '926') {                             
                            if (resp.order.deliveryType && resp.order.deliveryType === 'express') {
                                _this.playAudio('expressnotification');
                            } else {
                                _this.playAudio('notification');
                            }
                            const checkStringNumber = isNaN(resp.order.customerName);
                            let stringArray = '';
                            if (!checkStringNumber) {
                                for (let i = 0; i < resp.order.customerName.length; i++) {
                                    stringArray = stringArray ? stringArray + ' ' + resp.order.customerName[i] : resp.order.customerName[i];
                                }
                            }
                            TextToSpeech('Hi You Have Received New Order From ' + (checkStringNumber ? resp.order.customerName : stringArray));

                            _this.sharedService.setRecentOrders(resp.order);
                        }
                    } else if (response.body && resp.sync) {
                        _this.sharedService.setUnsyncOrders(resp.sync.unsync);
                    } else if (response.body && resp.login) {
                        if (_this.authenticationService.getAuthToken() !== resp.login) {
                            _this.notify.showCloseMsg('info', 'Session Expired', 'Kindly login', () => {
                                _this.router.navigate(['/logout']);
                            });
                        }
                    } else if (response.body && resp.token) {
                        /*                    if (_this.authenticationService.getAuthToken() !== resp.token) {
                                                _this.notify.showCloseMsg('info', 'Session Expired', 'Kindly login', () => {
                                                    _this.router.navigate(['/logout']);
                                                });
                                            }*/
                    } else if (response.body && resp.update) {
                        _this.sharedService.setRecentOrders(resp);
                    } else if (response.body && resp.ticket && _this.authenticationService.getSkewCode() === '811'){
                        if(resp.ticket.ticketStatus === 'OPEN'){
                            TextToSpeech(`Hi ${resp.ticket.complainantPersonName} raised a ticket, Please review and resolve as soon as possible`);
                        }
                        _this.sharedService.setRecentTicket(resp.ticket)
                    } else if (response.body && resp.bulkOrderUpdateStatus && _this.authenticationService.getSkewCode() === '811'){
                        _this.sharedService.setBulkUpdateStatus(resp.bulkOrderUpdateStatus)
                    }
                });
                _this.getPendingOrders();
            },
            () => {
                _this.reconnected = true;
                clearTimeout(_this.timer);
                _this.timer = setTimeout(() => {
                    _this.connect(serviceId);
                }, 10000);
            });
    }

    getPendingOrders() {
     if (this.authenticationService.getSkewCode() === '744') {
                return;
        } else if (this.faSkews.includes(this.authenticationService.getSkewCode())) {
            this.faService.getOrders('', 'Pending', 0, 50, false).subscribe(res => {
                if (res && res.data) {
                    this.sharedService.setPendingOrderCount(res.data.length);
                    this.stopAudio();
                }
            });
        } 
        else if(this.authenticationService.getSkewCode() === '811'){
            this.ondcService.getOrders('', 'accepted', 0, 50, false).subscribe(res => {
                if (res && res.data) {
                    this.sharedService.setPendingOrderCount(res.data.length);
                    this.stopAudio();
                }
            });
        }
        else {
            if (!this.paymentSkews.includes(this.authenticationService.getSkewCode())) {
                this.ecomService.getOrders('', 'Pending', 0, 50, false).subscribe(res => {
                    if (res && res.data) {
                        this.sharedService.setPendingOrderCount(res.data.length);
                        this.stopAudio();
                    }
                });
            }
        }
    }

    sendPendingOrderCount() {
        this.sharedService.setPendingOrderCount(0);
    }

    sendPendingNotificationsCount() {
        this.sharedService.setPendingNotificationCount(0);
    }

    getNotificationsList() {
        const body = {
            'userId': this.authenticationService.getServiceUserId(),
            'vendor': this.authenticationService.getSkewName()
        };
        this.loginService.getNotification(body).subscribe(
            (data) => {
                if (data) {
                    this.sharedService.setPendingNotificationCount(data.unreadNotificationCount);
                }
            },
            (err) => {
                console.error(err);
            });
    }

    disconnect() {
        if (this.stompClient != null) {
            this.stompClient.disconnect();
        }
        this.sendPendingOrderCount();
        this.sendPendingNotificationsCount();
    }
}
