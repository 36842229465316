// export const menu_list = [];

export const menu_list = [
    {
        'id': '1',
        'name': 'Dashboard',
        'icon': 'dashboard',
        'link': 'ecommerce/dashboard',
        'open': false,
        'hidden': true,
    },
    {
        'id': '2',
        'name': 'Order',
        'open': false,
        'link': 'ecommerce/orders',
        'icon': 'assignment',
        'hidden': false,
    },
    {
        'id': '7',
        'name': 'Transaction Processing',
        'open': false,
        'link': 'ecommerce/trans-process',
        'icon': 'assignment',
        'hidden': false,
    },
    {
        'id': '3',
        'name': 'Store Listing',
        'open': false,
        'link': 'ecommerce/store-list',
        'icon': 'store',
        'hidden': false,
    },
    {
        'id': '8',
        'name': 'Store Edit',
        'open': false,
        'link': 'ecommerce/store-edit',
        'icon': 'store',
        'hidden': true,
    },
    {
        'id': '4',
        'name': 'Reports',
        'icon': 'table_view',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '401',
                'name': 'Item Master',
                'icon': 'view_list',
                'link': 'ecommerce/reports?reportId=1004&productId=5&referrerProduct=truealerts',
                'open': false,
                'hidden': false,
            },
            {
                'id': '402',
                'name': 'Sales Order',
                'icon': 'receipt',
                'link': 'tables/featured',
                'open': false,
                'hidden': false,
            }
        ]

    },
    {
        'id': '5',
        'name': 'Configuration',
        'open': false,
        'link': false,
        'icon': 'settings',
        'hidden': false,
        'sub': [
            {
                'id': '501',
                'name': 'Food Aggregators Configuration',
                'open': false,
                'link': 'ecommerce/config/FoodAggregators',
                'icon': 'shop',
                'hidden': true,
            },
            {
                'id': '502',
                'name': 'Store Configuration',
                'open': false,
                'link': 'ecommerce/store-config',
                'icon': 'shopping_cart',
                'hidden': false,
            },
            {
                'id': '503',  // UrbanPiperItemConfiguration
                'name': 'Item Configuration',
                'open': false,
                'link': 'ecommerce/urbanpiper-item-config',
                'icon': 'shopping_basket',
                'hidden': false,
            },
            {
                'id': '505',
                'name': 'JumboTail Configuration',
                'open': false,
                'link': 'ecommerce/config/jumbotail',
                'icon': 'fastfood',
                'hidden': false,
            },
            {
                'id': '507',
                'name': 'MPesa Configuration',
                'open': false,
                'link': 'wallet/config/mpesa',
                'icon': 'fastfood',
                'hidden': false,
            },
            {
                'id': '509',
                'name': 'Order Easy Store Setup',
                'open': false,
                'link': 'ecommerce/config/OrderEasy',
                'icon': 'store',
                'hidden': true,
            },
            {
                'id': '511',  // UrbanpiperCategoryConfiguration
                'name': 'Category Configuration',
                'open': false,
                'link': 'ecommerce/urbanpiper-category-config',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '513', // WooCommerce Configuration
                'name': 'WooCommerce',
                'open': false,
                'link': 'ecommerce/config/WooCommerce',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '613',
                'name': 'Data Sync',
                'open': false,
                'link': 'ecommerce/sync-configuration',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '713', //  Scheduler
                'name': 'Scheduler',
                'open': false,
                'link': 'ecommerce/scheduler',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '515',
                'name': 'License Report',
                'open': false,
                'link': 'wallet/config/licenseDetails',
                'icon': 'list',
                'hidden': false,
            },
            {
                'id': '516',
                'name': 'Order Easy App Configuration',
                'open': false,
                'link': 'ecommerce/config/OrderEasy-app',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '517',
                'name': 'Bajaj Integration Configuration',
                'open': false,
                'link': 'ecommerce/config/BajajFinance',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '518',
                'name': 'Ewards Integration Configuration',
                'open': false,
                'link': 'wallet/config/Ewards',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '519',
                'name': 'Paytm Integration Configuration',
                'open': false,
                'link': 'wallet/config/paytm',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '609',
                'name': 'Ezetap Configuration',
                'open': false,
                'link': 'wallet/config/ezetap-report',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '699',
                'name': 'Ezetap Configuration',
                'open': false,
                'link': 'wallet/config/ezetap',
                'icon': 'phonelink_setup',
                'hidden': true,
            },
            {
                'id': '577',
                'name': 'PayU Configuration',
                'open': false,
                'link': 'wallet/config/payu',
                'icon': 'phonelink_setup',
                'hidden': true,
            },
            {
                'id': '547',
                'name': 'PayU Configuration',
                'open': false,
                'link': 'wallet/config/payu-report',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '520',
                'name': 'Shopify Integration Configuration',
                'open': false,
                'link': 'ecommerce/config/Shopify',
                'icon': 'phonelink_setup',
                'hidden': true,
            },
            {
                'id': '521',
                'name': 'Charm Health Configuration',
                'open': false,
                'link': 'ecommerce/config/CharmHealth',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                'id': '523',
                'name': 'Store Toggle History',
                'open': false,
                'link': 'ecommerce/store-toggle-dtl',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '527',
                'name': 'POS Registration',
                'open': false,
                'link': 'ecommerce/config/ordereasyConfigReport',
                'icon': 'store',
                'hidden': false,
            },
            {
                'id': '526',
                'name': 'Shopify',
                'open': false,
                'link': 'ecommerce/config/Shopify-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '528',
                'name': 'Outlet Configuration',
                'open': false,
                'link': 'ecommerce/config/OrderEasySta',
                'icon': 'phonelink_setup',
                'hidden': true,
            },
            {
                'id': '529',
                'name': 'Outlet List',
                'open': false,
                'link': 'ecommerce/config/OrderEasySta-report',
                'icon': 'store',
                'hidden': false,
            },
            {
                'id': '530',
                'name': 'Banner & News',
                'open': false,
                'link': 'ecommerce/config/bannerNewsConfig',
                'icon': 'panorama',
                'hidden': false,
            },
            {
                'id': '531',
                'name': 'App settings',
                'open': false,
                'link': 'ecommerce/config/commonConfig',
                'icon': 'settings_applications',
                'hidden': false,
            },
            {
                'id': '532',
                'name': 'Recommendation',
                'open': false,
                'link': 'ecommerce/config/dynamicRecommendationConfig',
                'icon': 'announcement',
                'hidden': false,
            },
            {
                'id': '534',
                'name': 'Order Easy',
                'open': false,
                'link': 'ecommerce/config/oeConfigReport',
                'icon': 'build',
                'hidden': false,
            },
            {
                'id': '535',
                'name': 'Order Easy Store Setup',
                'open': false,
                'link': 'ecommerce/config/oeConfiguration',
                'icon': 'store',
                'hidden': true,
            },
            {
                'id': '536',
                'name': 'POS Registration',
                'open': false,
                'link': 'ecommerce/config/amazon',
                'icon': 'store',
                'hidden': false,
            },
            {
                'id': '538',     // DeliveryApp - Configuration
                'name': 'Online Payment',
                'icon': 'payment',
                'link': 'ecommerce/config/onlinePaymentConfig',
                'open': false,
                'hidden': false,
            },
            {
                'id': '539',
                'name': 'Food Aggregator',
                'open': false,
                'link': 'ecommerce/config/urbanpiper-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '540',
                'name': 'Alert~Configuration',
                'open': false,
                'link': 'ecommerce/alert-config',
                'icon': 'panorama',
                'hidden': false,
            },
            {
                'id': '541',
                'name': 'Delivery Charges',
                'open': false,
                'link': 'ecommerce/config/deliveryConfiguration',
                'icon': 'directions_bike',
                'hidden': false,
            },
            {
                'id': '542',
                'name': 'Delivery Slot',
                'open': false,
                'link': 'ecommerce/delivery-slot',
                'icon': 'access_time',
                'hidden': false,
            },
            {
                'id': '543',
                'name': 'BillEasy Configuration',
                'open': false,
                'link': 'billeasy/billeasy-configuration',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '544',
                'name': 'RazorPay Configuration',
                'open': false,
                'link': 'wallet/config/razorpay',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '546',
                'name': 'App Intro',
                'open': false,
                'link': 'ecommerce/splashScreen',
                'icon': 'phonelink_setup',
                'hidden': false,
            },
            {
                "id": "545",
                "name": "Unicommerce Configuration",
                "open": false,
                "link": "unicommerce/unicommerce-configuration-report",
                "icon": "compare_arrows",
                "hidden": false
            },
        ]
    },
    {
        'id': '6',
        'name': 'Data Sync',
        'icon': 'sync',
        'open': false,
        'link': false,
        'hidden': false,
        'sub': [
            {
                'id': '601',
                'name': 'Automatic Sync',
                'icon': 'swap_vert',
                'link': 'ecommerce/auto-sync',
                'open': false,
                'hidden': false,
            },
            {
                'id': '602',
                'name': 'Manual Sync',
                'icon': 'get_app',
                'link': 'ecommerce/manual-sync',
                'open': false,
                'hidden': false,
            },
            {
                'id': '603',
                'name': 'About',
                'icon': 'get_app',
                'link': 'ecommerce/about-details',
                'open': false,
                'hidden': false,
            },
            {
                'id': '604',
                'name': 'Data Flush',
                'icon': 'cached',
                'link': 'ecommerce/data-flush-sync',
                'open': false,
                'hidden': false,
            },
        ]
    },
    {
        'id': '10',
        'name': 'App Users',
        'icon': 'dashboard',
        'link': 'ecommerce/app-user',
        'open': false,
        'hidden': false,
    },
    {
        'id': '702',
        'name': 'Outlet Setup',
        'icon': 'outlet',
        'link': 'ecommerce/live-tracking-map/outlet',
        'open': false,
        'hidden': false,
    },
    {
        'id': '800',
        'name': 'Maps',
        'icon': 'add_location',
        'open': false,
        'link': false,
        'hidden': false,
        'sub': [
            {
                'id': '801',
                'name': 'Live Tracking',
                'icon': 'directions',
                'link': 'ecommerce/live-tracking-map/live',
                'open': false,
                'hidden': false,
            },
            {
                'id': '802',
                'name': 'History Tracking',
                'icon': 'history',
                'link': 'ecommerce/live-tracking-map/history',
                'open': false,
                'hidden': false,
            }
        ]
    },
    {
        'id': '9',
        'name': 'Reports',
        'icon': 'table_view',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '901',
                'name': 'Distance Report',
                'icon': 'directions_bike',
                'link': 'ecommerce/live-tracking-distReport',
                'open': false,
                'hidden': false,
            },
            {
                'id': '902',
                'name': 'User Activity Report',
                'icon': 'directions_run',
                'link': 'ecommerce/live-tracking-userActReport',
                'open': false,
                'hidden': false,
            },
            {
                'id': '903',
                'name': 'Paytm Transaction Report',
                'icon': 'sort-amount-up',
                'link': 'wallet/config/paytmTransDetails',
                'open': false,
                'hidden': false,
            },
            {
                'id': '524',  // UrbanpiperItemUpload
                'name': 'Item Master',
                'open': false,
                'link': 'ecommerce/urbanpiper-item-master',
                'icon': 'donut_small',
                'hidden': false,
            },
            {
                'id': '525',
                'name': 'Tax Master Report',
                'icon': 'view_list',
                'link': 'ecommerce/tax-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '522',
                'name': 'Store Toggle Report',
                'open': false,
                'link': 'ecommerce/store-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '614',
                'name': 'Sync Tracker Report',
                'open': false,
                'link': 'ecommerce/syncTracker',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '615',
                'name': 'Sync Summary Report',
                'open': false,
                'link': 'ecommerce/syncReport',
                'icon': 'store',
                'hidden': false,
            },
            {
                'id': '907',
                'name': 'Modifier Report',
                'open': false,
                'link': 'ecommerce/modifier-master',
                'icon': 'view_list',
                'hidden': false,
            },
            {
                'id': '908',
                'name': 'Modifier Group Report',
                'open': false,
                'link': 'ecommerce/modifiergrp-master',
                'icon': 'view_list',
                'hidden': false,
            },
            {
                'id': '909',
                'name': 'Customer Report',
                'open': false,
                'link': 'ecommerce/customer-master',
                'icon': 'account_box',
                'hidden': false,
            },
            {
                'id': '910',
                'name': 'Item Report ',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1009/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '911',
                'name': 'Category Report ',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1008/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '912',
                'name': 'Sales order Summary',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1006/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '913',
                'name': 'Sales order details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1007/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '914',
                'name': 'Order Sync Failure',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1005/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '915',
                'name': 'Audit Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1004/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '916',
                'name': 'Sync Tracker',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1003/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '917',
                'name': 'Tax Report',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1002/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '918',
                'name': 'Customer Report',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1001/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '920',
                'name': 'Item Report ',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1010/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '921',
                'name': 'Category Report ',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1011/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '922',
                'name': 'Sales order Summary',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1012/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '923',
                'name': 'Sales order details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1013/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '924',
                'name': 'Tax Report',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1014/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '925',
                'name': 'Customer Report',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1015/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '926',
                'name': 'Item Report',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1019/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '927',
                'name': 'Sales Order Summary',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1018/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '928',
                'name': 'Sales Bill Summary',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1016/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '929',
                'name': 'Sales Bill Details',
                'icon': 'account_box',
                'link': 'ecommerce/sr-report/1017/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '930',
                'name': 'MPESA Transaction Details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1001/5/payment',
                'open': false,
                'hidden': false,
            },

            {
                'id': '931',
                'name': 'Store Toggle History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1022/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '932',
                'name': 'Item Toggle History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1023/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '933',
                'name': 'PAYTM Transaction Details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1002/5/payment',
                'open': false,
                'hidden': false,
            },
            {
                'id': '934',
                'name': 'Modifier Master Details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1024/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '935',
                'name': 'Item Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1027/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '936',
                'name': 'Category Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1035/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '937',
                'name': 'Customer Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1026/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '938',
                'name': 'Tax Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1025/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '939',
                'name': 'Shipping Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1032/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '940',
                'name': 'Online Payment History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1033/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '941',
                'name': 'Sales Orders',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1028/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '942',
                'name': 'Ordered Items',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1029/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '943',
                'name': 'Item Wise SO',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1036/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '944',
                'name': 'Customer Wise SO',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1034/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '945',
                'name': 'Synced Failed SO',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1030/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '946',
                'name': 'Item Master',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1037/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '947',
                'name': 'Sales Order Summary',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1038/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '948',
                'name': 'Sales Order Detail',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1039/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '949',
                'name': 'Item Wise SO',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1040/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '950',
                'name': 'Broadcast Notification',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1041/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '951',
                'name': 'Notification History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1042/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '952',
                'name': 'Menu Publish History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1044/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '954',
                'name': 'RazorPay Transaction Report',
                'icon': 'sort-amount-up',
                'link': 'wallet/config/razorpay-report',
                'open': false,
                'hidden': false,
            },
            {
                'id': '953',
                'name': 'Price Modification History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1047/5/ecommerce',
                'open': false,
                'hidden': false,
            },
            {
                'id': '955',
                'name': 'Menu Summary History',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1048/5/ecommerce',
                'open': false,
                'hidden': false
            },
            {
                'id': '956',
                'name': 'PayU Transaction Details',
                'icon': 'view_list',
                'link': 'ecommerce/sr-report/1003/5/payment',
                'open': false,
                'hidden': false,
            }
        ]
    },
    {
        'id': '11',
        'name': 'About',
        'icon': 'info',
        'link': 'ecommerce/live-tracking-info',
        'open': false,
        'hidden': false,
    },
    {
        'id': '12',
        'name': 'App Build',
        'icon': 'phonelink_setup',
        'link': 'ecommerce/app-build/list',
        'open': false,
        'hidden': false,
    },
    {
        'id': '13',
        'name': 'App Build',
        'icon': 'phonelink_setup',
        'link': 'ecommerce/app-build/detail',
        'open': false,
        'hidden': true,
    },
    {
        'id': '14',
        'name': 'App Build',
        'icon': 'phonelink_setup',
        'link': 'ecommerce/app-build/process',
        'open': false,
        'hidden': true,
    },
    {
        'id': '16',     // DeliveryApp - DeliveryAllocation
        'name': 'Delivery Allocation',
        'open': true,
        'link': 'delivery-app/delivery-allocation',
        'icon': 'departure_board',
        'hidden': false,
    },
    {
        'id': '17',     // DeliveryApp - DeliveryStatus
        'name': 'Delivery Status',
        'open': false,
        'link': 'delivery-app/delivery-status',
        'icon': 'drive_eta',
        'hidden': false,
    },
    {
        'id': '18',     // DeliveryApp - AboutUs
        'name': 'About',
        'icon': 'info',
        'link': 'delivery-app/about',
        'open': false,
        'hidden': false,
    },
    {
        'id': '19',
        'name': 'Session Management',
        'icon': 'today',
        'link': 'delivery-app/session-master',
        'open': false,
        'hidden': false,
    },
    {
        'id': '20',     // DeliveryApp - Configuration
        'name': 'Configuration',
        'open': false,
        'link': 'delivery-app/configuration',
        'icon': 'settings',
        'hidden': false,
    },
    {
        'id': '22',     // DeliveryApp - OutletConfiguration
        'name': 'POS Registration',
        'open': false,
        'link': 'delivery-app/outlet-configuration',
        'icon': 'room_preferences',
        'hidden': false,
    },
    {
        'id': '494',     // DeliveryApp - OutletConfiguration
        'name': 'Trade Tracking',
        'open': false,
        'link': 'delivery-app/trade-tracking',
        'icon': 'trade_tracking',
        'hidden': false,
    },
    {
        'id': '9999',
        'name': 'UnderConstruction',
        'icon': 'sync',
        'open': false,
        'link': 'ecommerce/under-construct',
        'hidden': true,
    },
    {
        'id': '9404',
        'name': 'Masters',
        'icon': 'storage',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '9434',
                'name': 'Item Master',
                'open': false,
                'link': 'ecommerce/oes-item-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '9435',
                'name': 'Category Master',
                'open': false,
                'link': 'ecommerce/oes-category-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '9436',
                'name': 'Customer Master',
                'open': false,
                'link': 'ecommerce/oes-customer-master',
                'icon': 'account_box',
                'hidden': false,
            },
            {
                'id': '9437',
                'name': 'Tax Master',
                'icon': 'view_list',
                'link': 'ecommerce/tax-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9438',
                'name': 'Master bulk upload',
                'icon': 'view_list',
                'link': 'ecommerce/master-create',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9439',
                'name': 'Upload Status Report',
                'icon': 'assignment',
                'link': 'ecommerce/oes-upload-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9440',
                'name': 'Language Master',
                'icon': 'language',
                'link': 'ecommerce/language-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '905',
                'name': 'Items Configuration',
                'open': false,
                'link': 'ecommerce/item-report',
                'icon': 'shopping_basket',
                'hidden': false,
            },
            {
                'id': '906',
                'name': 'Category Configuration',
                'open': false,
                'link': 'ecommerce/category-report',
                'icon': 'donut_small',
            },
            {
                'id': '9441003',
                'name': 'Item Master',
                'icon': 'assignment',
                'link': 'ecommerce/sta-pos/item',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9443',
                'name': 'Category Master',
                'icon': 'assignment',
                'link': 'ecommerce/sta-pos/categoryVal',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9444',
                'name': 'Tax Master',
                'icon': 'assignment',
                'link': 'ecommerce/sta-pos/tax',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9445',
                'name': 'Customer Master',
                'icon': 'account_box',
                'link': 'ecommerce/sta-pos/customer',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9446',
                'name': 'Onboarding Wizward',
                'icon': 'assignment',
                'link': 'ecommerce/sta-pos/onboard',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9441',
                'name': 'Recommended Master',
                'open': false,
                'link': 'ecommerce/recommended-master',
                'icon': 'shopping_basket',
                'hidden': false,
            },
            {
                'id': '9449',
                'name': 'Payment Master',
                'icon': 'payment',
                'link': 'ecommerce/payment-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9450',
                'name': 'Order Status Master',
                'icon': 'timeline',
                'link': 'ecommerce/order-status-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9451',
                'name': 'AddOn Master',
                'icon': 'timeline',
                'link': 'ecommerce/addOn-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9452',
                'name': 'Bulk Image Upload',
                'open': false,
                'link': 'ecommerce/bulk-upload-image',
                'icon': 'photo_library',
                'hidden': false,
            },
            {
                'id': '1501',
                'name': 'Delivery Boy',
                'icon': 'directions_bike',
                'link': 'delivery-app/delivery-boy',
                'open': false,
                'hidden': false,
            },
            {
                'id': '1502',
                'name': 'Vehicle Master',
                'icon': 'local_shipping',
                'link': 'delivery-app/vehicle-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '1503',
                'name': 'Reason Master',
                'icon': 'feedback',
                'link': 'delivery-app/reason-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '1504',
                'name': 'Slot Master',
                'icon': 'access_time',
                'link': 'delivery-app/slot-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '1506',
                'name': 'Tender Master',
                'icon': 'payment',
                'link': 'delivery-app/tender-master',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9453',
                'name': 'WhatsNew Release Notes',
                'icon': 'fiber_new',
                'link': 'ecommerce/whatsNew',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9454',
                'name': 'SMS Configuration',
                'icon': 'fiber_new',
                'link': 'ecommerce/smsAdminConfig',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9455',
                'name': 'Employee Master',
                'icon': 'person',
                'link': 'ecommerce/employeeMaster',
                'open': false,
                'hidden': false,
            },
            {
                'id': '9456',
                'name': 'Role Master',
                'icon': 'accessibility',
                'link': 'ecommerce/roleMaster',
                'open': false,
                'hidden': false,
            }
        ]
    },
    {
        'id': '10000',
        'name': 'Transactions',
        'icon': 'list',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '10001',
                'name': 'Sales Orders',
                'open': false,
                'link': 'ecommerce/oes-order-report',
                'icon': 'shopping_cart',
                'hidden': false,
            },
            {
                'id': '10002',
                'name': 'Sales Orders',
                'open': false,
                'link': 'ecommerce/sales-order',
                'icon': 'shopping_cart',
                'hidden': false,
            },
            {
                'id': '10005',
                'name': 'Assign Order Processing',
                'open': false,
                'link': 'ecommerce/assign-order',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '904',
                'name': 'Sales Orders',
                'open': false,
                'link': 'ecommerce/order-report',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '10003',
                'name': 'Order Processing',
                'open': false,
                'link': 'ecommerce/amazon/order/processing',
                'icon': 'shopping_cart',
                'hidden': false,
            },
            {
                'id': '10004',     // DeliveryApp - Delivery OrderPrcessing
                'name': 'Delivery OrderProcessing',
                'open': false,
                'link': 'delivery-app/order-processing',
                'icon': 'departure_board',
                'hidden': false,
            },
            {
                'id': '10006',
                'name': 'Order Process',
                'open': false,
                'link': 'ecommerce/amazon/order/order-process',
                'icon': 'shopping_cart',
                'hidden': false,
            }
        ]
    },
    {
        'id': '20000',
        'name': 'Tools',
        'icon': 'build',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '20001',
                'name': 'Bulk Upload',
                'icon': 'backup',
                'link': 'ecommerce/sta-pos/bulk',
                'open': false,
                'hidden': false,
            },
            {
                'id': '20002',
                'name': 'Item Mapping',
                'open': false,
                'link': 'ecommerce/itemMapping',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '20003',
                'name': 'Item Mapping',
                'open': false,
                'link': 'ecommerce/shopify-item-mapping',
                'icon': 'assignment',
                'hidden': false,
            },
            {
                'id': '919',
                'name': 'Item Mapping',
                'open': false,
                'link': 'ecommerce/item-mapping',
                'icon': 'view_list',
                'hidden': false,
            },
            {
                "id": "20005",
                "name": "Item Mapping",
                "open": false,
                "link": "unicommerce/item-mapping",
                "icon": "assignment"
            }
        ]
    },
    {
        'id': '24',
        'name': 'About',
        'icon': 'info',
        'link': 'ecommerce/oes-about-screen',
        'open': false,
        'hidden': false,
    },
    {
        'id': '23',
        'name': 'login truepos',
        'icon': 'info',
        'link': 'ecommerce/sta-login',
        'open': false,
        'hidden': true,
    },
    // {
    //     'id': '25',
    //     'name': 'Assure Care',
    //     'open': false,
    //     'link': 'ecommerce/chat-bot',
    //     'icon': 'contact_support',
    //     'hidden': false,
    // },
    {
        'id': '30000',
        'name': 'Notifications',
        'icon': 'notification_important',
        'link': false,
        'open': false,
        'hidden': false,
        'sub': [
            {
                'id': '30001',
                'name': 'Customer',
                'open': false,
                'link': 'ecommerce/config/smsEmailConfig',
                'icon': 'perm_identity',
                'hidden': false,
            },
            {
                'id': '30002',
                'name': 'Admin',
                'open': false,
                'link': 'ecommerce/config/notification-admin',
                'icon': 'manage_accounts',
                'hidden': false,
            },
            {
                'id': '30003',
                'name': 'Broadcast',
                'open': false,
                'link': 'ecommerce/config/notification-broadcast',
                'icon': 'sensors',
                'hidden': false,
            },
            {
                'id': '30004',
                'name': 'Settings',
                'open': false,
                'link': 'ecommerce/config/notification-configuration',
                'icon': 'settings_cell',
                'hidden': false,
            }
        ]
    },
    {
        'id': '26',
        'name': 'Mobile App',
        'open': false,
        'link': false,
        'icon': 'settings',
        'hidden': false,
        'sub': [
            {
                'id': '2601',
                'name': 'App Builder',
                'open': false,
                'link': 'ecommerce/order-easy/app-builder',
                'icon': 'app_settings_alt',
                'hidden': false,
            },
            {
                'id': '2602',
                'name': 'Dynamic Menu',
                'open': false,
                'link': 'ecommerce/order-easy/dynamic-menu',
                'icon': 'menu_open',
                'hidden': false,
            },
            {
                'id': '2603',
                'name': 'Admin Build',
                'open': false,
                'link': 'ecommerce/admin/build',
                'icon': 'menu_open',
                'hidden': false,
            },
            {
                'id': '2604',
                'name': 'App Request',
                'open': false,
                'link': 'ecommerce/app-request',
                'icon': 'menu_open',
                'hidden': false,
            },
            {
                'id': '2605',
                'name': 'App Requests',
                'open': false,
                'link': 'ecommerce/admin/app-request',
                'icon': 'menu_open',
                'hidden': false,
            },
            {
                'id': '2606',
                'name': 'Notification Configuration',
                'open': false,
                'link': 'ecommerce/admin/app-request',
                'icon': 'menu_open',
                'hidden': false,
            },
            {
                'id': '2607',
                'name': 'Release Details',
                'open': false,
                'link': 'ecommerce/admin/app-release-details',
                'icon': 'menu_open',
                'hidden': false,
            },
            {
                'id': "2608",
                "name": "App Publish Details",
                "open": false,
                "link": "ecommerce/admin/app-publish-details",
                "icon": "menu_open",
                "hidden": false
            }
        ]
    },
    {
        'id': '27',     // DeliveryApp - ReturnItemManagement
        'name': 'Sales Return Management',
        'open': true,
        'link': 'delivery-app/return-item-management',
        'icon': 'departure_board',
        'hidden': false,
    },
    {
        'id': '28',     // DeliveryApp - DataSync
        'name': 'Data Sync',
        'open': true,
        'link': 'delivery-app/data-sync',
        'icon': 'departure_board',
        'hidden': true,
    },
    {
        'id': '10111',     // dpAdmin
        'name': 'Run Sql',
        'icon': 'phonelink_setup',
        'link': 'ecommerce/dp-admin',
        'open': false,
        'hidden': false,
    },
    {
        'id': '55',
        'name': 'App Build',
        'icon': 'phonelink_setup',
        'link': 'ondc-admin/dashboard/ondc-admin/ecommerce',
        'open': false,
        'hidden': false,
    },
];


export const skew_menu = {
    '744:1': ['10', '800', '801', '802', '9', '901', '11'],
    '535:1': ['1', '2', '3', '5', '501', '502', '503', '551', '6', '8', '602', '401', '613', '615', '524', '9', '904', '525', '906', '907', '908', '910', '911', '914', '916', '931', '932', '952', '934', '10000', '539', '952', '953', '955'],
    '737:1': [],
    '739:1': ['1', '5', '503', '505', '6', '602', '614'],
    '740:1': ['1', '7', '5', '505', '503', '6', '602', '614'],
    '739,740:1': ['1', '7', '5', '505', '503', '6', '601', '602', '614'],
    '730:1': ['1', '5', '507', '9', '930'],
    '741:1': [],
    '762:1': ['1', '5', '531', '530', '532', '9', '534', '535', '920', '921', '922', '923', '924', '925', '528', '9404', '10000', '530', '9441', '9443', '9444', '9445', '23', '20000', '20001', '9441003'],
    '762765:1': ['1', '5', '531', '530', '532', '9', '534', '535', '920', '921', '922', '923', '924', '925', '528', '9404', '10000', '530', '9441', '9443', '9444', '9445', '23', '20000', '20001', '9441003', '1501', '1503', '1505', '537', '10004'],
    '745:1': ['5', '6', '602', '513', '613', '614', '615', '713', '20000', '20002', '9', '924', '926', '927', '928', '929', '923', '915', '9404', '905', '30000', '30002'],
    '742:1': ['1', '2', '5', '602', '604', '509', '527', '530', '531', '532', '538', '541', '542', '546', '9404', '9', '905', '906', '9448', '9452', '9455', '9456', '9404', '10000', '10002', '935', '936', '937', '938', '939', '940', '941', '942', '943', '944', '945', '26', '2601', '2602', '2604', '30000', '30001', '30002', '30003', '30004', '950', '951', '20'],
    '754:1': ['5', '6', '602', '521', '613', '614', '615'],
    '757:1': ['5', '517'],
    '1:1': ['12', '13', '14', '9404', '9440', '9449', '9450', '9451', '9453', '9454', '26', '2603', '2605', '2607', '2608'],
    '753:1': ['5', '518', '515'],
    '720:1': ['5', '519', '9', '903', '933'],
    '751:1': ['5', '609', '699'],
    '777:1': ['5', '547', '577', '9', '956'],
    '774:1': ['5', '544', '9', '954'],
    '522:1': ['5', '6', '602', '613', '520', '614', '615', '713', '526', '9', '20000', '20003', '924', '926', '927', '923', '10000', '10005', '928'],
    // '742-538:1': ['1', '2', '5', '6', '602', '509', '516', '526', '614', '615', '9', '904', '905', '906', '525', '15', '1501', '1503', '1505', '16', '17', '20', '21'],
    '538:1': ['16', '19', '20', '22', '27', '9404', '1501', '1503', '1506', '28'],
    '765:1': ['9404', '1501', '1503', '1505', '5', '537', '10000', '10004'],
    '764:1': ['10000', '10003', '10006', '5', '602', '20000', '536', '919', '9', '946', '947', '948', '949'],
    '773:1': ['5', '543'],
    '776:1': ["5", "6", "545", "613", "20000", "20005", "27", "271", "272", "9", "962", "963", "964", "965", "966", "967"],
    '811:1': [],
    '809:1': [],
    '906:1': [],
    '808:1': [],
    '111:1': ['10111'],
    '8111:1': [],
    '721:1': [],
    '911:1': [],
    '915:1': [],
    '768:1': [],
    '919:1': [],
    '519:1': [],
    '532:1': [],
    '931:1': [],
    '932:1': [],
    '926:1': [],
    '934:1': [],
    '533:1': [],
    '729:1': [],
    '724:1': [],
    '939:1': [],
    '940:1': [],
    '941:1': [],
    '746:1': [],
    '942:1': [],
    '717:1': []
};

export const default_page = {
    '535': {
        defaultPage: '10002',
        configPage: '501',
        skewName: 'urbanpiper'
    },
    '739': {
        defaultPage: '7',
        configPage: '505',
        skewName: 'JumboTail',
    },
    '740': {
        defaultPage: '7',
        configPage: '505',
        skewName: 'JumboTail',
    },
    '739,740': {
        defaultPage: '7',
        configPage: '505',
        skewName: 'JumboTail',
    },
    '753': {
        defaultPage: '518',
        configPage: '518',
        skewName: 'Ewards',
    },
    '720': {
        defaultPage: '519',
        configPage: '519',
        skewName: 'Paytm',
    },
    '751': {
        defaultPage: '609',
        configPage: '609',
        skewName: 'Ezetap',
    },
    '777': {
        defaultPage: '547',
        configPage: '547',
        skewName: 'PayU',
    },
    '774': {
        defaultPage: '544',
        configPage: '544',
        skewName: 'razorpay',
    },
    '730': {
        defaultPage: '507',
        configPage: '507',
        skewName: 'Mpesa',
    },
    '742': {
        defaultPage: '10002',
        configPage: '509',
        skewName: 'OrderEasy',
    },
    '765': {
        defaultPage: '21',
        configPage: '20',
        skewName: 'DeliveryWebApp',
    },
    '744': {
        defaultPage: '10',
        configPage: '10',
        skewName: 'LiveTracking',
    },
    '745': {
        defaultPage: '513',
        configPage: '513',
        skewName: 'WooCommerce',
    },
    '757': {
        defaultPage: '517',
        configPage: '517',
        skewName: 'BajajFinance',
    },
    '754': {
        defaultPage: '521',
        configPage: '521',
        skewName: 'CharmHealth',
    },
    '522': {
        defaultPage: '526',
        configPage: '520',
        skewName: 'Shopify',
    },
    '1': {
        defaultPage: '12',
        configPage: '12',
        skewName: 'Admin'
    },
    '538': {
        defaultPage: '16',
        configPage: '20',
        skewName: 'DeliveryApp'
    },
    '762': {
        defaultPage: '23',
        configPage: '528',
        skewName: 'OrderEasyStandAlone',
    },
    '762765': {
        defaultPage: '23',
        configPage: '528',
        skewName: 'OrderEasyStandAlone',
    },
    '764': {
        defaultPage: '536',
        configPage: '536',
        skewName: 'Amazon',
    },
    '773': {
        defaultPage: '543',
        configPage: '543',
        skewName: 'BillEasy'
    },
    '111': {
        defaultPage: '10111',
        configPage: '10111',
        skewName: 'dpadmin'
    },
    '8111': {
        defaultPage: '55',
        configPage: '55',
        skewName: 'ondc-admin'
    },
    '776': {
        defaultPage: '545',
        configPage: '545',
        skewName: 'unicommerce'
    }
};

export const master_url = {
    'item': {
        value: '/masters/index.html#/products/list/',
    },
    'category': {
        value: '/masters/index.html#/categoryhead/list/',
    },
    'categoryVal': {
        value: '/masters/index.html#/productcategories/list/',
    },
    'tax': {
        value: '/masters/index.html#/taxes/list/',
    },
    'customer': {
        value: '/masters/index.html#/customers/list/',
    },
    'onboard': {
        value: '/startupWizard/index.html',
    },
    'bulk': {
        value: '/com.gofrugal.raymedi.wpmigration.WPMigration/WPMigration.html?dbstr=csdb',
    }
};

export const profileFeatures = {
    '742': [
        {
            "id": 1,
            "featureName": "Pos Registration",
            "routerLink": "Integration/ecommerce/config/ordereasyConfigReport"
        },
        {
            "id": 2,
            "featureName": "Data Sync",
            "routerLink": "Integration/ecommerce/manual-sync"
        },
        {
            "id": 3,
            "featureName": "Image upload",
            "routerLink": "Integration/ecommerce/item-report"
        },
        {
            "id": 4,
            "featureName": "App Upload",
            "routerLink": "Integration/ecommerce/app-request"
        },
        {
            "id": 5,
            "featureName": "App personalization",
            "routerLink": "Integration/ecommerce/order-easy/app-builder"
        },
        {
            "id": 6,
            "featureName": "Banner and news",
            "routerLink": "Integration/ecommerce/config/bannerNewsConfig"
        },
        {
            "id": 7,
            "featureName": "Delivery Config",
            "routerLink": "Integration/ecommerce/delivery-slot"
        },
        {
            "id": 8,
            "featureName": "First order",
            "routerLink": "Integration/ecommerce/ordereasy/sales-order"
        },
        {
            "id": 9,
            "featureName": "Offer Management",
            "routerLink": "Integration/ecommerce/ordereasy-offer-management"
        },
        {
            "id": 10,
            "featureName": "Chatbot Management",
            "routerLink": "Integration/ecommerce/chat-bot"
        }
    ]
};
