import { Injectable } from '@angular/core';
import { User } from '../models';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { default_page } from './menu-element';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private currentUser: User;
  private orderEasyLicenseDetails: any;
  public addonList = [];

  constructor(
    private notify: NotificationService,
    private router: Router,
    private cookieService: CookieService,
  ) {
  }
  private loadUser(): void {
    if (sessionStorage.getItem('currentUser')) {
      this.currentUser = Object.assign(new User(), JSON.parse(this.decryptUsingAES256(sessionStorage.getItem('currentUser'))));
    } else {
        this.router.navigate(['/login']);
    }
  }

  encryptUsingAES256(data: string) {
    return CryptoJS.AES.encrypt(data, this.cookieService.get('X-Auth-Token')).toString();
  }
  decryptUsingAES256(data: string) {
    return CryptoJS.AES.decrypt(data, this.cookieService.get('X-Auth-Token')).toString(CryptoJS.enc.Utf8);
  }

  encryptUsingMD5(data: string) {
    return CryptoJS.MD5(data).toString();
  }

  public isLoggedIn(): boolean {
    return sessionStorage.getItem('currentUser') ? true : false;
  }

  public loginUser(user: User): void {
    this.currentUser = user;
    // sessionStorage.setItem('LOGIN_ID', user.email);
    sessionStorage.setItem('currentUser', this.encryptUsingAES256(JSON.stringify(user)));
  }

  public getAuthToken(): string {
    this.loadUser();
    return this.currentUser.authKey;
  }

  public getRedirectionToken(): string {
    return this.cookieService.get('X-Redirect-Token').toString();
  }

  public getServiceUserId(): number {
    this.loadUser();
    return this.currentUser ? this.currentUser.serviceUserId : null;
    // return 4242;
  }

  public getEmailId(): string {
    this.loadUser();
    const emailId: string = this.currentUser.email;
    return emailId.trim();
  }

  public getRoleId(): number {
    this.loadUser();
    return this.currentUser.roleId;
  }

  //Get CustomerID
  public getCustomerId(): string {
    this.loadUser();
    return this.currentUser.custId;
  }

  //Set CustomerID
  public setCustomerId(customerID: string): void {
    this.loadUser();
    this.currentUser.custId = customerID;
    this.loginUser(this.currentUser);
  }

  // Get VerticalID
  public getVerticalID(): string {
    this.loadUser();
    return this.currentUser.verticalId;
  }

  // Get Base Product
  public getBaseProduct(): string {
    this.loadUser();
    return this.currentUser.baseProduct ? this.currentUser.baseProduct : '';
  }

  public getUserName(): string {
    this.loadUser();
    return this.currentUser.userName;
  }

  public getOutlet(): string {
    this.loadUser();
    return this.currentUser.outletId;
  }

  public setSkewCode(productCode: string): void {
    this.loadUser();
    this.currentUser.skewCode = productCode;
    this.loginUser(this.currentUser);
  }

  public getSkewCode(): string {
    this.loadUser();
    return this.currentUser.skewCode;
  }

  public getDomainUrl(): string {
    this.loadUser();
    return this.currentUser.domainUrl;
  }

  public getDomainPassword(): string {
    this.loadUser();
    return this.currentUser.domainPassword;
  }

  public getIsConfigured(): number {
    this.loadUser();
    return this.currentUser.isConfigured;
  }

  public setIsConfigured(isConfigured: number): void {
    this.loadUser();
    this.currentUser.isConfigured = isConfigured;
    this.loginUser(this.currentUser);
  }

  public setSkewName(productName: string): void {
    this.loadUser();
    this.currentUser.skewName = productName;
    this.loginUser(this.currentUser);
  }

  public getSkewName(): string {
    this.loadUser();
    if (!this.currentUser.skewName) {
      this.setSkewName(this.getDefSkewName(this.getSkewCode()));
    }
    return this.currentUser.skewName;
  }

  public getAuthEmpId(): number {
    this.loadUser();
    return this.currentUser.authEmpId;
  }

  public getAuthId(): number {
    this.loadUser();
    return this.currentUser.authId;
  }

  public logoutUser(): void {
    this.currentUser = null;
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('ordereasylicense');
  }

  // Kept seperate here cuz of circular dependency
  private getDefSkewName(skewCode: string) {
    return default_page[skewCode]['skewName'];
  }

  public saveOrderEasyLicenseDetails(licenseDetails: any): void {
    this.orderEasyLicenseDetails = licenseDetails;
    sessionStorage.setItem('ordereasylicense', this.encryptUsingAES256(JSON.stringify(licenseDetails)));
  }

  private loadOrderEasyLicenseDetails(): void {
    if (sessionStorage.getItem('ordereasylicense')) {
      this.orderEasyLicenseDetails = Object.assign([], JSON.parse(this.decryptUsingAES256(sessionStorage.getItem('ordereasylicense'))));
    } else {
      this.notify.showWarn('Warning', 'Session Expired, Kindly login again');
      this.router.navigate(['/logout']);
    }
  }

  public checkOrderEasyAddonIsActive(skewCode: string) {
    this.loadOrderEasyLicenseDetails();
    const status = this.orderEasyLicenseDetails.filter(res => res.skewCode == skewCode)[0].status;
    if (status) { return status.toLowerCase() === 'active' ? true : false; } else { return false; }
  }

  public getSelectedOutletId() {
    if (localStorage.getItem('selectedOutletId')) {
      return localStorage.getItem('selectedOutletId');
    }
  }

  public getOutletLicenseCount() {
    if (sessionStorage.getItem('ordereasylicense')) {
      this.orderEasyLicenseDetails = Object.assign([], JSON.parse(this.decryptUsingAES256(sessionStorage.getItem('ordereasylicense'))));
    } else {
      this.notify.showWarn('Warning', 'Session Expired, Kindly login again');
      this.router.navigate(['/logout']);
    }
    return this.orderEasyLicenseDetails.filter(res => res.skewCode == '01.0AO')[0].licenseCount;
  }

  public getSelectedOutletName() {
    if (localStorage.getItem('selectedOutletName')) {
      return localStorage.getItem('selectedOutletName');
    }
  }

  public  getCompanyCode() {
    if (localStorage.getItem('companyCode')) {
      return localStorage.getItem('companyCode') ? JSON.parse(localStorage.getItem('companyCode')).companyCode : '';
    }
  }


  public getAddonDetails(skewCode) {
     let isActive = false;
     this.addonList.forEach( x => {
       if (x.productCode === skewCode) {
         isActive = x.purchased && !x.expired;
       }
     });
     return isActive;
  }

  public logout(){
    localStorage.removeItem('selectedOutletId')
    localStorage.removeItem('selectedOutletName')
    localStorage.removeItem('outletDetails')
    localStorage.removeItem('selectedOutlet')
    localStorage.removeItem('companyCode');
    localStorage.removeItem('companyAvailiable')
    this.router.navigate(['/logout']);
  }

  public isNewServer() : boolean {
    console.log('To be processed in new server ', this.cookieService.get('isNew'));
    if (this.cookieService.get('isNew') === null) {
      return false;
    }
    return this.cookieService.get('isNew').toLowerCase() === 'true'
  }

}
