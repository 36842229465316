import { Injectable } from '@angular/core';
import { ConfigData } from '../data';
import { EcommerceAPIService } from '../../../../../../core/http/ecommerce/ecommerce-api.service';
import { AuthenticationService } from '../../../../../../core/services/authentication.service';
import { Observable, of } from 'rxjs';
import { AmazonConfig, OutletDetail, Product, AmazonKeys } from '../../../../../../core/models/integration/ecommerce';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { AccountApiService } from '../../../../../../core/http/account/account-api.service';

@Injectable({
  providedIn: 'root'
})
export class AmazonConfigService {

  configData = ConfigData;
  constructor(
    private ecommerceAPIService: EcommerceAPIService,
    private accountService: AccountApiService,
    private authenticationService: AuthenticationService
  ) { }

  public getOutletDetails(): Observable<OutletDetail[]> {
    const param = new HttpParams().set('loginId', encodeURIComponent(this.authenticationService.getEmailId()));
    const header = new HttpHeaders().set('skew', this.authenticationService.getSkewCode()).set('userId', ''+this.authenticationService.getServiceUserId());
    return this.accountService.get('micro_service/store/details', param, header);
  }

  public getProduct(): Observable<Product[]> {
    return of(this.configData.urbanpiper.product);
  }

  public testURL(url: string, product: string): Observable<any> {
    const param = new HttpParams().set('url', window.btoa(url)).set('product', product)
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('store/test/domain-url', param);
  }

  public genOTP(url: string, domain: string): Observable<any> {
    const param = new HttpParams().set('url', window.btoa(url)).set('skewCode', '522').set('domain', domain);
    return this.ecommerceAPIService.get('store/regen/otp', param);
  }

  public saveConfig(selectedOutletId: any, body: AmazonConfig): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName())
      .set('outletId', selectedOutletId);
    return this.ecommerceAPIService.post('configure', param, header, body, true);
  }

  public saveAmazonKeys(body: AmazonKeys): Observable<any> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.post('amazon/seller-credentials', param, header, body, true);
  }

  public getConfiguredKeys(): Observable<AmazonKeys> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('amazon/get-seller-credentials', param, header, true);
  }
  public getConfig(outletId: number): Observable<AmazonConfig[]> {
    const header = new HttpHeaders().set('Content-Type', 'application/json');
    const param = new HttpParams()
      .set('userId', '' + this.authenticationService.getServiceUserId())
      .set('outletId', '' + outletId)
      .set('vendor', this.authenticationService.getSkewName());
    return this.ecommerceAPIService.get('get/configured-store', param, header, true);
  }

}
